
import { IonContent, IonPage, IonButton, IonIcon } from '@ionic/vue';
import {
  arrowBackOutline,
  optionsOutline,
  ellipsisHorizontal,
  calendarClear,
  location,
} from 'ionicons/icons';
import HeaderMain from '@/components/shared/HeaderMain.vue';
export default {
  name: 'Home',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonIcon,
    HeaderMain,
  },
  setup() {
    const openExternalUrl = function (externalUrl: any) {
      window.open(externalUrl, '_system');
    };

    return {
      arrowBackOutline,
      optionsOutline,
      ellipsisHorizontal,
      calendarClear,
      location,
      openExternalUrl,
    };
  },
};
